  .bannerSection {
    background-color: #f5f5f5;
    padding: 80px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .bannerContent {
    text-align: center;
  }
  
  .bannerTitle {
    font-family: 'Poppins', sans-serif;
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 16px;
    color: #50414f;
  }

  .bannerSubTitle {
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 16px;
    color: #50414f;
  }
  
  .bannerSubtitle {
    font-size: 18px;
    font-weight: 600;
    line-height: 2rem;
    color: #50414f;
  }
  
  /* Estilos responsive */
  @media (max-width: 768px) {
    .bannerSection {
      padding: 60px 20px;
    }
  
    .bannerTitle {
      font-size: 32px;
    }
  
    .bannerSubtitle {
      font-size: 16px;
    }
  }
  
  @media (max-width: 480px) {
    .bannerSection {
      padding: 40px 10px;
    }
  
    .bannerTitle {
      font-size: 24px;
    }
  
    .bannerSubtitle {
      font-size: 14px;
    }
  }