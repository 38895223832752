.technologiesSection {
    padding: 2rem;
    background-color: #f5f5f5;
    padding: 3% 4% 4% 4%;
  }
  
  .technologiesSection h2 {
    font-size: 40px;
    text-align: center;
    color: var(--color-secondary);
    margin-bottom: 1rem;
  }
  
  .techSlide {
    padding: 0 10px;
  }
  
  .techSlide img {
    width: 180px;
    height: 95px;
    object-fit: contain;
  }

  @media (max-width: 768px) {
    .technologiesSection {
      padding: 8% 4% 13% 4%;
    }

    .technologiesSection h2 {
      font-size: 30px;
      text-align: center;
      color: var(--color-secondary);
    }


  }