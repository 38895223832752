.heroBanner {
  background-image: url('../hero-background.jpg');
  background-size: cover;
  background-position: center;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  padding: 6% 5%;
}

.heroBanner h1 {
  font-size: 55px;
  color: #50514f;
  margin-bottom: 1rem;
  font-weight: 900;
  padding: 0 17% 0 17%;
}

.heroBanner h2 {
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 3rem;
  color: #50514f;
}

.ctaButton {
  background-color: var(--color-cta);
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: var(--font-size-subtitle);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.ctaButton:hover {
  background-color: #501c06;
  border-radius: 4px;
}

@media (max-width: 768px) {
  .heroBanner {
      min-height: 300px;
      padding-top: 4rem;
      padding-bottom: 4rem;
  }

  .heroBanner h1 {
      font-size: 40px;
  }

  .heroBanner h2 {
      font-size: 24px;
  }
}

@media (max-width: 480px) {
  .heroBanner {
      min-height: 250px;
      padding-top: 4rem;
      padding-bottom: 4rem;
  }

  .heroBanner h1 {
      font-size: 23px;
      line-height: 2rem;
  }

  .heroBanner h2 {
      font-size: 16px;
  }
}