.aboutSection {
    padding: 4rem 0;
    background-color: #ffffff;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    align-items: center;
    padding: 0 2rem;
  }
  
  .textContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .textContent h2 {
    font-size: 40px;
    margin-bottom: 1rem;
    color: #50414f;
  }
  
  .textContent p {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 1rem;
    line-height: 1.6;
    color: #666;
  }
  
  .lottieContent {
    width: 350px;
    height: 350px;
    margin-right: 9rem;
    min-height: 300px;
  }
  
  @media (max-width: 768px) {
    .container {
      grid-template-columns: 1fr;
      gap: 1rem;
      padding: 0 1rem;
    }
  
    .textContent {
      text-align: center;
    }
  
    .textContent h2 {
      font-size: 32px;
    }
  
    .lottieContent {
      order: -1;
      width: 100%;
      height: auto;
      max-width: 300px;
      margin: 0 auto;
      min-height: 200px;
    }
  }
  
  @media (max-width: 480px) {
    .textContent h2 {
      font-size: 28px;
    }
  
    .lottieContent {
      max-width: 250px;
    }
  }