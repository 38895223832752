@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');

html {
  scroll-padding-top:90px; /* Ajusta este valor según la altura de tu navbar */
}

:root {
  --color-primary: #a8cf45;
  --color-secondary: #50414f;
  --color-cta: #EE5F21;
  --font-size-title: 45px;
  --font-size-subtitle: 16px;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
  margin: 0;
}

p {
  font-family:'Nunito Sans';
  font-size: 1 rem;
}

button {
  font-family: 'Poppins', sans-serif;
}