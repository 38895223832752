.servicesSection {
    padding: 2rem;
    background-color: #f5f5f5;
    padding: 3% 4% 5% 4%
  }
  
  .servicesSection h2 {
    font-size: 40px;
    text-align: center;
    color: var(--color-secondary);
    margin-bottom: 2rem;
  }
  
  .servicesContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
  }
  
  .serviceCard {
    background-color: white;
    border-radius: 8px;
    padding: 1.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .serviceCard:hover {
    transform: translateY(-5px);
  }
  
  .serviceImage {
    width: 160px;
    height: 160px;
    object-fit: cover;
    border-radius: 4px;
    margin-bottom: 1rem;
  }
  
  .serviceCard {
    font-size: 22px;
    color: #50414f;
    margin-bottom: 0.5rem;
    padding-top: 3rem;
  }
  
  .serviceCard p {
    color: var(--color-secondary);
    font-size: 1rem;
  }