.responsiveSection {
    padding: 4rem 0;
    background-color: #ffffff;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    align-items: center;
    padding: 0 2rem; /* Añadido para dar espacio en los lados */
  }
  
  .textContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .textContent h2 {
    font-size: 40px;
    margin-bottom: 1rem;
    color: #50414f;
  }
  
  .textContent p {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 1rem;
    line-height: 1.6;
    color: #666;
  }
  
  .lottieContent {
    width: 350px;
    height: 350px;
    margin-left: 9rem;
    min-height: 300px;
  }
  
  @media (max-width: 768px) {
    .container {
      grid-template-columns: 1fr;
      gap: 1rem;
      padding: 0 1rem; /* Reducido para móviles */
    }

    .responsiveSection {
        padding: 1rem;
    }

    .textContent {
      text-align: center; /* Centra el texto en móviles */
    }
  
    .textContent h2 {
      font-size: 34px; /* Reducido para móviles */
    }
  
    .lottieContent {
      order: -1;
      width: 100%; /* Ocupa todo el ancho disponible */
      height: auto; /* Altura automática */
      max-width: 300px; /* Limita el tamaño máximo */
      margin: 0 auto; /* Centra el Lottie */
      min-height: 200px; /* Ajustado para móviles */
    }
  }
  
  /* Para pantallas aún más pequeñas */
  @media (max-width: 480px) {
    .textContent h2 {
      font-size: 30px; /* Aún más pequeño para pantallas muy pequeñas */
    }
  
    .lottieContent {
      max-width: 250px; /* Más pequeño para pantallas muy pequeñas */
    }
  }